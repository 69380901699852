import { Injectable, signal } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(private spinner: NgxSpinnerService) {}
  spinnerText = signal('');
  showSpinner(spinnerText: string = '') {
    this.spinnerText.update(() => spinnerText);
    this.spinner.show();
  }

  hideSpinner(): void {
    this.spinner.hide();
  }
}
